import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login/index";
import desktop from "../components/desktop/index";
// import { component } from "vue/types/umd";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "bgc_login",
    component: () => import("@/views/bgc_login/index.vue"),
  },
  {
    path: "/loginview",
    name: "loginview",
    component: () => import("@/views/loginView/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/login_error",
    name: "login_error",
    component: () => import("@/views/login_error/login_error.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/nomessage/message_error.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("@/views/demo/demo.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index"),
    redirect: "/desktop",
    children: [
      {
        path: "/desktop",
        name: "desktop",
        component: desktop,
      },
      {
        path: "/faceList",
        name: "face",
        component: () => import("@/components/face/index"),
      },
      {
        path: "/faceLog",
        name: "log",
        component: () => import("@/components/faceLog/index"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path === "/") return next();
//   let face_token = localStorage.getItem("face_token");
//   if (!face_token) return next("/");
//   next();
// });

export default router;
