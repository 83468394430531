<template>
  <div style="width: 100%;height:100%">
    <div></div>
    <canvas id="canvas" style="z-index: 1;"></canvas>
    <div class="login"></div>
    <div class="title" style="z-index:2">
      <span class="txt">人工智能心理咨询技能陪练系统</span>
    </div>
    <!--登录中间块-->
    <div class="login-mid9" style="z-index: 2;">
      <div class="login-mid-top">
        <div class="shadow-top-left"></div>
        <div class="shadow-top-right"></div>
      </div>
      <div class="login-mid-mid">
        <!--捕获人脸区域-->
        <div class="videoCamera-canvasCamera">
          <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
          <canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>

          <!--人脸特效区域-->
          <div v-if="faceImgState" class="face-special-effects-2"></div>
          <div v-else class="face-special-effects3"></div>
        </div>
      </div>
      <div class="login-mid-bot">
        <div class="shadow-bot-left"></div>
        <div class="shadow-bot-right"></div>
      </div>
      <!--按钮区域-->
      <div class="face-btn">
        <button @click="faceVef()">{{faceImgState?'正在识别中...':'开始识别'}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import $camera from "../../camera/index.js";
export default {
  data() {
    return {
      videoWidth: 200,
      videoHeight: 200,
      msg: "",
      faceImgState: false,
      faceOption: {},
      audioUrl: require("../../assets/ttsmaker-file-2024-9-2-1-36-8.mp3")
    };
  },
  mounted() {
    this.makeStar();
    //调用摄像头
    this.faceOption = $camera.getCamera({
      videoWidth: 200,
      videoHeight: 200,
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      canvasId: "canvasCamera",
      videoId: "videoCamera"
    });
    console.log(this.faceOption);
    //this.getCompetence();
  },
  methods: {
    makeStar() {
      var canvas = document.getElementById("canvas"),
        ctx = canvas.getContext("2d"),
        w = (canvas.width = canvas.offsetWidth),
        h = (canvas.height = canvas.offsetHeight),
        hue = 217,
        stars = [],
        count = 0,
        maxStars = 150;
      var canvas2 = document.createElement("canvas"),
        ctx2 = canvas2.getContext("2d");
      canvas2.width = 100;
      canvas2.height = 100;
      var half = canvas2.width / 2,
        gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
      gradient2.addColorStop(0.025, "#fff");
      gradient2.addColorStop(0.1, "hsl(" + hue + ", 61%, 33%)");
      gradient2.addColorStop(0.25, "hsl(" + hue + ", 64%, 6%)");
      gradient2.addColorStop(1, "transparent");
      ctx2.fillStyle = gradient2;
      ctx2.beginPath();
      ctx2.arc(half, half, half, 0, Math.PI * 2);
      ctx2.fill();
      // End cache
      function random(min, max) {
        if (arguments.length < 2) {
          max = min;
          min = 0;
        }
        if (min > max) {
          var hold = max;
          max = min;
          min = hold;
        }
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      var Star = function() {
        this.orbitRadius = random(w / 2 - 50);
        this.radius = random(100, this.orbitRadius) / 20;
        this.orbitX = w / 2;
        this.orbitY = h / 2;
        this.timePassed = random(0, maxStars);
        this.speed = random(this.orbitRadius) / 10000000;
        this.alpha = random(2, 10) / 10;
        count++;
        stars[count] = this;
      };
      Star.prototype.draw = function() {
        var x =
            Math.sin(this.timePassed) * this.orbitRadius * 1.5 + this.orbitX,
          y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
          twinkle = random(10);
        if (twinkle === 1 && this.alpha > 0) {
          this.alpha -= 0.05;
        } else if (twinkle === 2 && this.alpha < 1) {
          this.alpha += 0.05;
        }
        ctx.globalAlpha = this.alpha;
        ctx.drawImage(
          canvas2,
          x - this.radius / 2,
          y - this.radius / 2,
          this.radius,
          this.radius
        );
        this.timePassed += this.speed;
      };
      for (var i = 0; i < maxStars; i++) {
        new Star();
      }
      function animation() {
        ctx.globalCompositeOperation = "source-over";
        ctx.globalAlpha = 1;
        ctx.fillStyle = "hsla(" + hue + ", 64%, 10%, 1)";
        ctx.fillRect(0, 0, w, h);
        ctx.globalCompositeOperation = "lighter";
        for (var i = 1, l = stars.length; i < l; i++) {
          stars[i].draw();
        }
        window.requestAnimationFrame(animation);
      }
      animation();
    },
    onEnded() {},
    playAudio() {
      console.log("11");
      this.audioUrl = "";
      // 本地链接
      this.voicePath = new URL(
        "../../assets/ttsmaker-file-2024-9-2-1-36-8.mp3",
        import.meta.url
      ).href;
      this.$nextTick(() => {
        this.$refs.audioPlayer.value?.play();
      });
    },
    // 调用后台接口
    faceVef() {
      // 开始绘制图片
      let imageBase = $camera.draw(this.faceOption);
      if (this.faceImgState) {
        return;
      }
      this.faceImgState = true;
      if (imageBase === "" || imageBase === null || imageBase === undefined) {
        this.$message.error("图片数据为空");
      } else {
        // this.$router.push("/login_error");

        this.$http.post("/face/vef", { imageBase }).then(
          res => {
            console.log(res);
            this.faceImgState = false;
            // 跳转首页
            // 如果返回的状态码为200，表示验证成功，处理返回的数据
            if (res.data.code === 200) {
              // 停止摄像头
              this.faceOption.thisVideo.srcObject.getTracks()[0].stop();
              // 将验证成功的token和用户名存储在localStorage中
              localStorage.setItem("face_token", res.data.token);
              localStorage.setItem("username", res.data.name);

              if (res.data.name !== "admin") {
                // const token = encodeURIComponent(res.data.token);
                // const username = encodeURIComponent(res.data.name);
                window.location.assign(
                  "https://student.xinyixinedu.cn/#/ipadViews/check?token=" +
                    res.data.token +
                    "&username=" +
                    res.data.name
                );
              } else {
                this.$router.push("/home");
              }
            }
            if (res.data.code === 201) {
              this.$message.success(res.data.msg);
            }
          },
          onerror => {
            this.faceImgState = false;
            this.$router.push("/login_error");
            this.faceImgState = false;
          }
        );
      }
    }
  }
};
</script>
<style scoped>
/* @import "./index.css"; */
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: url(~@/assets/login/login-bg.png) no-repeat;
  background-size: cover;
  filter: blur(10px);
}
.txt {
  position: absolute;
  font-size: 20px;
  font-weight: 400;
  top: 20%;
  letter-spacing: 12px;
  left: 36%;
  color: rgba(133, 235, 255, 1);
  text-align: left;
  vertical-align: top;
}
.title {
  top: 2%;
  position: absolute;
  width: 100%;
  height: 80px;
  /* border: 1px solid red; */
  background: url(../../assets/login/title.png) no-repeat;
  background-size: 100%;
  /* background-size: cover; */
  /* padding: 3px; */
  /* background-position: center center; 调整图片位置 */
  overflow: visible; /* 显示所有图片内容 */
}

.login-mid9 {
  position: absolute;
  width: 45% !important;
  height: 40% !important;
  /* background-color: rgba(37, 76, 102, 0.5); */
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  cursor: pointer;
  border-radius: 3.85px;
  background: linear-gradient(
    90deg,
    rgba(27, 48, 77, 0) 0%,
    rgba(29, 57, 94, 1) 50.55%,
    rgba(27, 48, 77, 0) 100%
  );
  border: 0.97px solid rgba(71, 215, 255, 1);
}
.login-mid-top,
.login-mid-bot {
  width: 100%;
  height: 10%;
}
.shadow-top-left {
  float: left;
  width: 50px;
  height: 100%;
  border-top: 2px solid #00ffff;
  border-left: 2px solid #00ffff;
  filter: blur(2px);
}
.shadow-top-right {
  float: right;
  width: 50px;
  height: 100%;
  border-top: 2px solid #00ffff;
  border-right: 2px solid #00ffff;
  filter: blur(2px);
}
.shadow-bot-left {
  float: left;
  width: 50px;
  height: 100%;
  border-bottom: 2px solid #00ffff;
  border-left: 2px solid #00ffff;
  filter: blur(2px);
}
.shadow-bot-right {
  float: right;
  width: 50px;
  height: 100%;
  border-bottom: 2px solid #00ffff;
  border-right: 2px solid #00ffff;
  filter: blur(2px);
}
.login-mid-mid {
  width: 100%;
  height: 80%;
}
.login-mid:hover .shadow-top-left,
.login-mid:hover .shadow-top-right,
.login-mid:hover .shadow-bot-left,
.login-mid:hover .shadow-bot-right {
  border-color: #fff;
  filter: blur(1px);
}
.videoCamera-canvasCamera {
  position: relative;
  width: 200px;
  top: 15px;
  height: 200px;
  margin: 0 auto;
}
.face-special-effects3,
.face-special-effects-2 {
  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 99999999999 !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.face-special-effects3 {
  background: url(~@/assets/login/face.gif) no-repeat;
  background-size: 300px 300px;
}
.face-special-effects-2 {
  background: url(~@/assets/login/face_detection.gif) no-repeat;
  background-size: 300px 300px;
}
.face-btn {
  width: 100%;
  height: 50px;
  z-index: 999999999999 !important;
  margin-top: 45px;
  text-align: center;
}
.face-btn button {
  background-color: #00ffff;
  border: none;
  outline: none;
  width: 200px;
  height: 40px;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
.msg {
  width: 100%;
  height: 75px;
  color: #ffffff;
}
.server-msg {
  width: 100%;
  height: 35px;
  text-align: center;
  line-height: 35px;
}
.welcome {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-family: "Baskerville Old Face";
  color: white;
}
canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>